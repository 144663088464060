export function distinct<T>(value: T, index: number, self: T[]) {
  return self.indexOf(value) === index;
}

export function filterByMaxValue<T>(
  items: ReadonlyArray<T>,
  valueSelector: (item: T) => number,
): Array<T> {
  const maxValue = Math.max(...items.map(valueSelector));
  return items.filter((item) => valueSelector(item) === maxValue);
}

/**
 * Typeguard to filter out null or undefined values.
 */
export function isNotNullish<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export function interlace<T>(values: ReadonlyArray<T>): ReadonlyArray<T> {
  return [...values.filter((_, i) => i % 2 === 0), ...values.filter((_, i) => i % 2 === 1)];
}
