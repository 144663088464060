import { Alert, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { WordPlacement } from 'types/WordPlacement';
import { getBestWordPlacements } from 'utils/wordsUtils';
import { WordPlacementsDisplay } from './WordPlacementsDisplay';

interface IProps {
  words: ReadonlyArray<string>;
  letters: ReadonlyArray<string>;
}

export function BestWordPlacementsLoader(props: IProps) {
  const { words, letters } = props;

  const [error, setError] = useState<string>();
  const [wordPlacements, setWordPlacements] = useState<ReadonlyArray<WordPlacement>>();

  useEffect(() => {
    if (!error && !wordPlacements) {
      try {
        setWordPlacements(getBestWordPlacements(words, letters));
      } catch (e) {
        setError((e as Error).message);
      }
    }
  }, [words, letters]);

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  if (wordPlacements) {
    return <WordPlacementsDisplay wordPlacements={wordPlacements} />;
  }

  // TODO: this spinner won't actually show until we do getBestWordPlacements on a worker thread.
  return (
    <>
      <CircularProgress size="1rem" /> <i>calculating...</i>
    </>
  );
}
