import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { kebabCase } from 'change-case';
import { PropsWithChildren, useState } from 'react';

interface IProps {
  buttonLabel: string;
  dialogTitle: string;
}

export function OpenDialogButton(props: PropsWithChildren<IProps>) {
  const { buttonLabel, dialogTitle, children } = props;

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const titleId = kebabCase(dialogTitle);

  return (
    <>
      <Button variant="outlined" onClick={handleOpen}>
        {buttonLabel}
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs" aria-labelledby={titleId}>
        <DialogTitle id={titleId}>{dialogTitle}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
