import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { kebabCase } from 'change-case';
import { PropsWithChildren, ReactNode, useState } from 'react';

interface IProps {
  buttonLabel: string;
  dialogTitle?: string;
  icon: ReactNode;
  onClose?: () => void;
}

export function OpenDialogListItemButton(props: PropsWithChildren<IProps>) {
  const { buttonLabel, dialogTitle = buttonLabel, icon, onClose, children } = props;

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    if (onClose) {
      onClose();
    }
  };

  const titleId = kebabCase(dialogTitle);

  return (
    <>
      <ListItemButton onClick={handleOpen}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={buttonLabel} />
      </ListItemButton>
      <Dialog open={open} onClose={handleClose} aria-labelledby={titleId}>
        <DialogTitle id={titleId}>{dialogTitle}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
