import { WordPlacement } from 'types/WordPlacement';
import { WordValidationResult } from 'types/WordValidationResult';
import { distinct, filterByMaxValue, isNotNullish } from './arrayUtils';

export function validateWord(
  word: string,
  center: string,
  letters: ReadonlyArray<string>,
  validWords: ReadonlyArray<string>,
): WordValidationResult {
  if (word.length < 4) {
    return `Must be 4 letters or more`;
  }

  if (center && !word.includes(center)) {
    return `Must use ${center}`;
  }

  const invalidLetters = word
    .split('')
    .filter(distinct)
    .filter((letter) => letter !== center && !letters.includes(letter));
  if (invalidLetters.length > 1) {
    return `Invalid letters: ${invalidLetters.join(', ')}`;
  }
  if (invalidLetters.length > 0) {
    return `Invalid letter: ${invalidLetters}`;
  }

  if (!validWords.includes(word)) {
    return 'Not a playable word';
  }

  return true;
}

export function getWordScore(word: string, bonusLetter: string): number {
  if (word.length < 4) {
    return 0;
  }

  const letters = word.split('');
  const lengthScore = (word.length - 3) * 2 + (word.length >= 7 ? 4 + (word.length - 7) : 0);
  const bonusScore = letters.filter((letter) => letter === bonusLetter).length * 5;
  const pangramScore = letters.filter(distinct).length === 7 ? 7 : 0;

  return lengthScore + bonusScore + pangramScore;
}

export function getMaxWordScore(word: string, letters: ReadonlyArray<string>): number {
  return Math.max(...letters.map((letter) => getWordScore(word, letter)));
}

export function getTotalScore(placements: ReadonlyArray<{ score: number }>): number {
  return placements.map((placement) => placement.score).reduce((a, b) => a + b, 0);
}

/**
 * This function is used when there are not enough words to use every bonus letter.
 */
function getBestWordPlacementsInOrder(
  words: ReadonlyArray<string>,
  letters: ReadonlyArray<string>,
): ReadonlyArray<WordPlacement> {
  const requiredNumberOfResults = letters.length * 2;
  const results = new Array<WordPlacement>();

  const wordScores = words
    .map((word) => {
      const scores = letters.map((letter) => getWordScore(word, letter));
      const maxScore = Math.max(...scores);
      return { word, scores, maxScore };
    })
    // Highest max score first
    .sort((a, b) => b.maxScore - a.maxScore);

  let letterIndex = 0;
  while (results.length < requiredNumberOfResults) {
    const letter = letters[letterIndex];

    let wordsForThisLetter = wordScores.filter(({ word }) => word.includes(letter));

    if (wordsForThisLetter.length === 0) {
      // If no options, we have to ignore bonus letter
      wordsForThisLetter = wordScores;
    }

    const [{ word, scores }] = wordsForThisLetter.sort(
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      (a, b) => b.scores[letterIndex] - a.scores[letterIndex],
    );

    results.push({ word, letter, score: scores[letterIndex], maxScore: Math.max(...scores) });

    // Remove this word from the remaining options
    const indexToRemove = wordScores.findIndex((wordScore) => wordScore.word === word);
    wordScores.splice(indexToRemove, 1);

    if (word.includes(letter)) {
      // Advance to next letter
      letterIndex = (letterIndex + 1) % letters.length;
    }
  }

  return results;
}

/**
 * This function is used recursively to build combinations of placements and keep the best.
 */
function getBestWordPlacementsRecursively(
  words: ReadonlyArray<{ word: string; scores: Array<number>; maxScore: number }>,
  remainingLetterCounts: ReadonlyArray<number>,
): Array<{ word: string; letterIndex: number; score: number; maxScore: number }> {
  const [{ word, scores }] = words;
  const maxScore = Math.max(...scores);
  const candidatePlacements = scores
    .map((score, letterIndex) => {
      if (score > 0) {
        const placement = { word, letterIndex, score, maxScore };
        const nextScores = words.slice(1);
        const nextRemainingLetterCounts = remainingLetterCounts.slice();
        nextRemainingLetterCounts[letterIndex] -= 1;

        if (nextRemainingLetterCounts.every((count) => count === 0)) {
          return [placement];
        }
        if (nextRemainingLetterCounts[letterIndex] === 0) {
          for (let i = 0; i < nextScores.length; i += 1) {
            if (nextScores[i].scores[letterIndex] > 0) {
              nextScores[i] = {
                ...nextScores[i],
                scores: nextScores[i].scores.slice(),
              };
              nextScores[i].scores[letterIndex] = 0;
              nextScores[i].maxScore = Math.max(...nextScores[i].scores);
              if (nextScores[i].maxScore === 0) {
                nextScores.splice(i, 1);
                i -= 1;
              }
            }
          }
          nextScores.sort((a, b) => b.maxScore - a.maxScore);
        }
        if (nextScores.length === 0) {
          return [placement];
        }
        return [
          placement,
          ...getBestWordPlacementsRecursively(nextScores, nextRemainingLetterCounts),
        ];
      }
      return null;
    })
    .filter(isNotNullish);

  const [bestPlacements] = filterByMaxValue(candidatePlacements, getTotalScore);
  return bestPlacements;
}

export function getBestWordPlacements(
  words: ReadonlyArray<string>,
  letters: ReadonlyArray<string>,
): ReadonlyArray<WordPlacement> {
  const lastLetterIndex = letters.length - 1;
  const requiredNumberOfResults = letters.length * 2;
  if (words.length < requiredNumberOfResults) {
    throw new Error(`At least ${requiredNumberOfResults} words are required.`);
  }

  const topWordScoresMap = new Map<string, { scores: Array<number>; maxScore: number }>();
  letters.forEach((letter, letterIndex) => {
    // Take just the top scoring words for each letter -
    // this improves performance, while still guaranteeing every letter can be maximised.
    const numberOfWordsToTake =
      // Last letter requires one fewer placement, so can have one fewer word
      letterIndex === lastLetterIndex ? requiredNumberOfResults - 1 : requiredNumberOfResults;
    words
      .map((word) => (word.includes(letter) ? { word, score: getWordScore(word, letter) } : null))
      .filter(isNotNullish)
      // Put highest scoring first
      .sort((a, b) => b.score - a.score)
      .slice(0, numberOfWordsToTake)
      .forEach(({ word, score }) => {
        let topWordScores = topWordScoresMap.get(word);
        if (topWordScores === undefined) {
          topWordScores = {
            scores: letters.map((_, i) => (i === letterIndex ? score : 0)),
            maxScore: score,
          };
          topWordScoresMap.set(word, topWordScores);
        } else {
          topWordScores.scores[letterIndex] = score;
          topWordScores.maxScore = score > topWordScores.maxScore ? score : topWordScores.maxScore;
        }
      });
  });

  const topWordScores = Array.from(topWordScoresMap.entries())
    .map(([word, { scores, maxScore }]) => ({ word, scores, maxScore }))
    .sort((a, b) => b.maxScore - a.maxScore);

  // Last letter requires one fewer placement
  const remainingLetterCounts = letters.map((_, index) => (index < lastLetterIndex ? 2 : 1));
  const bestPlacements = getBestWordPlacementsRecursively(topWordScores, remainingLetterCounts);

  // Check if we didn't have enough words for each placement (ignoring last)
  if (bestPlacements.length < requiredNumberOfResults - 1) {
    return getBestWordPlacementsInOrder(words, letters);
  }

  // Order placements by higher score then letter
  bestPlacements.sort((a, b) => b.score - a.score).sort((a, b) => a.letterIndex - b.letterIndex);

  // For the last placement, take highest scoring unused word
  const usedWords = bestPlacements.map((placement) => placement.word);
  const unusedWords = words.filter((word) => !usedWords.includes(word));
  if (unusedWords.length > 0) {
    const lastLetter = letters[lastLetterIndex];
    const [bestScoringUnusedWord] = unusedWords
      .map((word) => ({ word, score: getWordScore(word, lastLetter) }))
      .sort((a, b) => b.score - a.score);
    const { word, score } = bestScoringUnusedWord;
    const maxScore = getMaxWordScore(word, letters);
    bestPlacements.push({ word, letterIndex: lastLetterIndex, score, maxScore });
  }

  return bestPlacements.map(({ letterIndex, ...placement }) => ({
    ...placement,
    letter: letters[letterIndex],
  }));
}
