import FilterVintageIcon from '@mui/icons-material/FilterVintage';
import { Button, Chip, Dialog, DialogActions, DialogContent, Stack } from '@mui/material';
import { useBlossomPuzzleData } from 'providers/BlossomPuzzleDataProvider';
import { useState } from 'react';
import { Letter } from './Letter';

function LetterDisplay(props: { value: string; isCenter?: boolean }) {
  const { value, isCenter } = props;

  return (
    <Chip
      color="primary"
      variant={isCenter ? 'filled' : 'outlined'}
      label={<Letter value={value} />}
      sx={{
        width: '2rem',
        '> span': { padding: '1px' },
      }}
    />
  );
}

export function LettersDisplay() {
  const { center, letters } = useBlossomPuzzleData();

  const [showLetterWheel, setShowLetterWheel] = useState(false);
  const [shuffledLetters, setShuffledLetters] = useState(letters);

  const handleOpenLetterWheel = () => {
    setShowLetterWheel(true);
  };

  const handleCloseLetterWheel = () => {
    setShowLetterWheel(false);
  };

  const handleShuffleLetters = () => {
    setShuffledLetters(letters.toSorted(() => Math.random() - 0.5));
  };

  const letterWheelSpacing = 4;

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <LetterDisplay value={center} isCenter />
        {letters.map((letter) => (
          <LetterDisplay key={letter} value={letter} />
        ))}
        <Button onClick={handleOpenLetterWheel} sx={{ minWidth: 0 }}>
          <FilterVintageIcon sx={{ rotate: '30deg' }} />
        </Button>
      </Stack>
      <Dialog open={showLetterWheel} onClose={handleCloseLetterWheel}>
        <DialogContent>
          <Stack spacing={letterWheelSpacing} alignItems="center">
            <Stack direction="row" spacing={letterWheelSpacing}>
              <LetterDisplay value={shuffledLetters[0]} />
              <LetterDisplay value={shuffledLetters[1]} />
            </Stack>
            <Stack direction="row" spacing={letterWheelSpacing}>
              <LetterDisplay value={shuffledLetters[2]} />
              <LetterDisplay value={center} isCenter />
              <LetterDisplay value={shuffledLetters[3]} />
            </Stack>
            <Stack direction="row" spacing={letterWheelSpacing}>
              <LetterDisplay value={shuffledLetters[4]} />
              <LetterDisplay value={shuffledLetters[5]} />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button onClick={handleShuffleLetters}>Shuffle</Button>
          <Button onClick={handleCloseLetterWheel}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
