import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { BlossomPuzzleData } from 'types/BlossomPuzzleData';
import { toFriendlyDate } from 'utils/dateUtils';

interface ILoadingState {
  isLoading?: boolean;
  error?: string;
}

interface IContext extends ILoadingState {
  dateKeys: Map<string, string> | undefined;
  getBlossomPuzzleData: (dateKey: string) => BlossomPuzzleData | undefined;
}

const BlossomPuzzlesContext = createContext<IContext | undefined>(undefined);

export function BlossomPuzzlesProvider({ children }: PropsWithChildren) {
  const [loadingState, setLoadingState] = useState<ILoadingState>({});
  const [blossomPuzzles, setBlossomPuzzles] = useState<Map<string, BlossomPuzzleData>>();
  const [dateKeys, setDateKeys] = useState<Map<string, string>>();

  const { isLoading } = loadingState;

  useEffect(() => {
    if (!isLoading && !blossomPuzzles) {
      setLoadingState({ isLoading: true });
      fetch('./data/blossom-data.min.json')
        .then((response) => {
          if (response.ok) {
            response.json().then((json: { [dateKey: string]: BlossomPuzzleData }) => {
              const puzzleEntries = Object.entries(json);
              setBlossomPuzzles(new Map(puzzleEntries));
              setDateKeys(
                new Map(
                  puzzleEntries.map(([key, { puzzleId }]) => [
                    key,
                    `${toFriendlyDate(key)}: ${puzzleId}`,
                  ]),
                ),
              );
              setLoadingState({});
            });
          } else {
            setLoadingState({ error: response.statusText });
          }
        })
        .catch((reason) => {
          setLoadingState({ error: reason.message });
        })
        .finally(() => {
          setLoadingState((prevState) => ({ ...prevState, isLoading: false }));
        });
    }
  }, []);

  const getBlossomPuzzleData = (dateKey: string): BlossomPuzzleData | undefined =>
    blossomPuzzles?.get(dateKey);

  return (
    <BlossomPuzzlesContext.Provider value={{ ...loadingState, dateKeys, getBlossomPuzzleData }}>
      {children}
    </BlossomPuzzlesContext.Provider>
  );
}

export function useBlossomPuzzles(): IContext {
  const context = useContext(BlossomPuzzlesContext);

  if (!context) {
    throw new Error('useBlossomPuzzles must be used inside a BlossomPuzzlesProvider.');
  }

  return context;
}
