import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useBlossomPuzzleData } from 'providers/BlossomPuzzleDataProvider';
import { useUserState } from 'providers/UserStateProvider';
import { useEffect, useState } from 'react';
import { getWordScore } from 'utils/wordsUtils';
import { Score } from './Score';
import { SlimButton } from './SlimButton';
import { Word } from './Word';

type WordScore = {
  word: string;
  scores: { [letter: string]: number };
  maxScore: number;
};

function sortWords(
  words: ReadonlyArray<WordScore>,
  sortByLetterScore: string | null,
): ReadonlyArray<WordScore> {
  if (sortByLetterScore) {
    return words.slice().sort((a, b) => b.scores[sortByLetterScore] - a.scores[sortByLetterScore]);
  }
  // If sortByLetterScore is null, sort alphabetically.
  return words.slice().sort((a, b) => a.word.localeCompare(b.word));
}

export function MyWordScores() {
  const { puzzleId, letters } = useBlossomPuzzleData();
  const { userState, updateUserState } = useUserState();

  const [sortByLetterScore, setSortByLetterScore] = useState<string | null>(null);
  const [wordScores, setWordScores] = useState<ReadonlyArray<WordScore>>([]);

  const puzzle = userState.puzzles[puzzleId];

  useEffect(() => {
    if (puzzle?.words) {
      setWordScores((prevWordScores) => {
        const nextWordScores = prevWordScores.slice();
        puzzle.words.forEach((word) => {
          if (!nextWordScores.some((w) => w.word === word)) {
            const scores = Object.fromEntries(
              letters.map((letter) => [letter, getWordScore(word, letter)]),
            );
            const maxScore = Math.max(...Object.values(scores));
            nextWordScores.push({ word, scores, maxScore });
          }
        });
        return sortWords(nextWordScores, sortByLetterScore);
      });
    }
  }, [puzzle?.words]);

  useEffect(() => {
    setWordScores((prevWordScores) => sortWords(prevWordScores, sortByLetterScore));
  }, [sortByLetterScore]);

  const placementWords = puzzle?.placements.map((placement) => placement.word) ?? [];
  const unplacedWordScores = wordScores.filter(({ word }) => !placementWords.includes(word));

  if (!puzzle || unplacedWordScores.length === 0) {
    return null;
  }

  function SortButton(props: { label: string; value: string | null }) {
    const { label, value } = props;

    return (
      <SlimButton
        fontWeight={sortByLetterScore === value ? 'bold' : 'normal'}
        onClick={() => setSortByLetterScore(value)}
      >
        {label}
      </SlimButton>
    );
  }

  const canAddPlacement = (letter: string) =>
    puzzle.placements.filter((placement) => placement.letter === letter).length < 2;

  const handleAddPlacement = (word: string, letter: string) => {
    updateUserState((prevUserState) => {
      const prevPuzzle = prevUserState.puzzles[puzzleId];
      const nextPlacements = prevPuzzle.placements.concat({ word, letter });
      nextPlacements.sort((a, b) => a.letter.localeCompare(b.letter));
      return {
        ...prevUserState,
        puzzles: {
          ...prevUserState.puzzles,
          [puzzleId]: {
            ...prevPuzzle,
            placements: nextPlacements,
          },
        },
      };
    });
  };

  return (
    <TableContainer>
      <Table padding="none">
        <TableHead>
          <TableRow>
            <TableCell>
              <SortButton label="Sort: A-Z" value={null} />
            </TableCell>
            {letters.map((letter) => (
              <TableCell key={letter} align="center">
                <SortButton label={letter} value={letter} />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {unplacedWordScores.map(({ word, scores, maxScore }) => (
            <TableRow key={word}>
              <TableCell>
                <Word>{word}</Word>
              </TableCell>
              {letters.map((letter) => (
                <TableCell key={letter} align="center">
                  {canAddPlacement(letter) ? (
                    <SlimButton
                      fontWeight={scores[letter] === maxScore ? 'bold' : 'normal'}
                      onClick={() => handleAddPlacement(word, letter)}
                    >
                      {scores[letter]}
                    </SlimButton>
                  ) : (
                    <Score value={scores[letter]} isMax={scores[letter] === maxScore} />
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
