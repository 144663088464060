import { Typography } from '@mui/material';

interface IProps {
  value: string;
}

export function Letter(props: IProps) {
  const { value } = props;

  return (
    <Typography component="span" sx={{ fontWeight: 'bold' }}>
      {value}
    </Typography>
  );
}
