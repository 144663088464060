import { PropsWithChildren, createContext, useContext } from 'react';
import { BlossomPuzzleData } from 'types/BlossomPuzzleData';

const BlossomPuzzleDataContext = createContext<BlossomPuzzleData | undefined>(undefined);

export function BlossomPuzzleDataProvider({
  value,
  children,
}: PropsWithChildren<{ value: BlossomPuzzleData }>) {
  return (
    <BlossomPuzzleDataContext.Provider value={value}>{children}</BlossomPuzzleDataContext.Provider>
  );
}

export function useBlossomPuzzleData(): BlossomPuzzleData {
  const context = useContext(BlossomPuzzleDataContext);

  if (!context) {
    throw new Error('useBlossomPuzzleData must be used inside a BlossomPuzzleDataProvider.');
  }

  return context;
}
