interface IProps {
  word: string;
}

export function LinkToDefinition(props: IProps) {
  const { word } = props;

  return (
    <a
      href={`https://merriam-webster.com/dictionary/${word.toLowerCase()}`}
      target="_blank"
      rel="noreferrer"
    >
      {word}
    </a>
  );
}
