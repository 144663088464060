import { Alert, AlertColor, Box } from '@mui/material';
import { differenceInDays } from 'date-fns';
import { useBlossomPuzzles } from 'providers/BlossomPuzzlesProvider';
import { useUserState } from 'providers/UserStateProvider';

export function AdminStatusBar() {
  const { userState } = useUserState();
  const { dateKeys } = useBlossomPuzzles();

  if (!userState.isAdmin || !dateKeys || dateKeys.size === 0) {
    return null;
  }

  const lastDateKey = Array.from(dateKeys.keys()).at(-1)!;

  const daysLeft = differenceInDays(new Date(lastDateKey), new Date());
  const severity: AlertColor = daysLeft > 3 ? 'info' : 'warning';

  return (
    <Box pt={2}>
      <Alert severity={severity}>Puzzle data: {daysLeft} days left</Alert>
    </Box>
  );
}
