import { Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

export function Word(props: PropsWithChildren<{}>) {
  const { children } = props;

  return (
    <Typography fontFamily="monospace" fontSize="1rem">
      {children}
    </Typography>
  );
}
