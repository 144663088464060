import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  Tooltip,
} from '@mui/material';
import { WordPlacement } from 'types/WordPlacement';
import { getTotalScore } from 'utils/wordsUtils';
import { Letter } from './Letter';
import { LinkToDefinition } from './LinkToDefinition';
import { Score } from './Score';
import { Word } from './Word';

interface IProps {
  wordPlacements: ReadonlyArray<WordPlacement>;
  onRemove?: (word: string, letter: string) => void;
}

export function WordPlacementsDisplay(props: IProps) {
  const { wordPlacements, onRemove } = props;

  const total = getTotalScore(wordPlacements);

  return (
    <TableContainer>
      <Table padding="none">
        <TableBody>
          {wordPlacements.map(({ word, letter, score, maxScore }) => (
            <TableRow key={word}>
              {onRemove && (
                <TableCell padding="checkbox">
                  <IconButton
                    aria-label="remove"
                    size="small"
                    onClick={() => onRemove(word, letter)}
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                </TableCell>
              )}
              <TableCell align="center">
                <Letter value={letter} />
              </TableCell>
              <TableCell>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Word>
                    <LinkToDefinition word={word} />
                  </Word>
                  {!word.includes(letter) && (
                    <Tooltip title="Does not use bonus letter!" enterTouchDelay={0}>
                      <WarningAmberIcon />
                    </Tooltip>
                  )}
                </Stack>
              </TableCell>
              <TableCell align="center">
                <Score value={score} isMax={score === maxScore} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        {wordPlacements.length > 1 && (
          <TableFooter>
            <TableRow>
              {onRemove && <TableCell />}
              <TableCell colSpan={2} align="right">
                Total:
              </TableCell>
              <TableCell align="center">{total}</TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
}
