import { PropsWithChildren, createContext, useContext, useState } from 'react';
import { getDateKey } from 'utils/dateUtils';

interface IContext {
  dateKey: string;
  updateDateKey: (dateKey: string) => void;
}

const DateKeyContext = createContext<IContext | undefined>(undefined);

export function DateKeyProvider({ children }: PropsWithChildren) {
  const [dateKey, updateDateKey] = useState(getDateKey(new Date()));

  return (
    <DateKeyContext.Provider value={{ dateKey, updateDateKey }}>{children}</DateKeyContext.Provider>
  );
}

export function useDateKey(): IContext {
  const context = useContext(DateKeyContext);

  if (!context) {
    throw new Error('useDateKey must be used inside a DateKeyProvider.');
  }

  return context;
}
