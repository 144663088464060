import {
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

/**
 * This type is serialized into localStorage so any changes must be backward compatible
 */
export type UserState = {
  isAdmin?: boolean;
  puzzles: {
    [puzzleId: string]: {
      words: Array<string>;
      placements: Array<{ word: string; letter: string }>;
    };
  };
};

const USER_STATE_STORAGE_KEY = 'BlossomWords:UserState';

interface IContext {
  userState: UserState;
  updateUserState: (update: SetStateAction<UserState>) => void;
}

const UserStateContext = createContext<IContext | undefined>(undefined);

export function UserStateProvider(props: PropsWithChildren<{}>) {
  const { children } = props;

  const userStateString = localStorage.getItem(USER_STATE_STORAGE_KEY);
  let initialUserState: UserState;
  if (userStateString) {
    initialUserState = JSON.parse(userStateString);

    // Migration: ensure placements array is initialised
    Object.values(initialUserState.puzzles).forEach((puzzle) => {
      // eslint-disable-next-line no-param-reassign
      puzzle.placements = puzzle.placements ?? [];
    });
  } else {
    initialUserState = { puzzles: {} };
    localStorage.setItem(USER_STATE_STORAGE_KEY, JSON.stringify(initialUserState));
  }

  const [userState, setUserState] = useState(initialUserState);

  const updateUserState = (update: SetStateAction<UserState>) => {
    setUserState(update);
  };

  useEffect(() => {
    localStorage.setItem(USER_STATE_STORAGE_KEY, JSON.stringify(userState));
  }, [userState]);

  return (
    <UserStateContext.Provider value={{ userState, updateUserState }}>
      {children}
    </UserStateContext.Provider>
  );
}

export function useUserState(): IContext {
  const userStateContext = useContext(UserStateContext);

  if (!userStateContext) {
    throw new Error('useUserState must be used inside an UserStateProvider.');
  }

  return userStateContext;
}
