import { format } from 'date-fns';

export function getLocalTimezoneDate(date: Date) {
  const offset = date.getTimezoneOffset();
  return new Date(date.getTime() - offset * 60 * 1000);
}

export function getDateKey(date: Date) {
  return getLocalTimezoneDate(date).toISOString().split('T')[0];
}

export function toFriendlyDate(dateKey: string): string {
  const date = new Date(dateKey);
  return format(date, 'MMM d');
}
