import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import MenuIcon from '@mui/icons-material/Menu';
import ShareIcon from '@mui/icons-material/Share';
import { Divider, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useUserState } from 'providers/UserStateProvider';
import { useState } from 'react';
import { AdminToggle } from './AdminToggle';
import { DownloadUserStateButton } from './DownloadUserStateButton';
import { MagicButton } from './MagicButton';
import { OpenDialogListItemButton } from './OpenDialogListItemButton';
import { SocialMediaPosts } from './SocialMediaPosts';
import { UploadUserStateButton } from './UploadUserStateButton';

export function Drawer() {
  const [open, setOpen] = useState(false);

  const { userState } = useUserState();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start">
        <MenuIcon />
      </IconButton>
      <MuiDrawer anchor="left" open={open} onClose={handleDrawerClose}>
        <Box role="presentation" sx={{ width: 250 }}>
          <List>
            <ListItem disablePadding onClick={handleDrawerClose}>
              <DownloadUserStateButton />
            </ListItem>
            <ListItem disablePadding>
              <UploadUserStateButton onClose={handleDrawerClose} />
            </ListItem>
          </List>
          <Divider />
          {userState.isAdmin ? (
            <List>
              <ListItem disablePadding>
                <OpenDialogListItemButton
                  buttonLabel="Social media posts"
                  icon={<ShareIcon />}
                  onClose={handleDrawerClose}
                >
                  <SocialMediaPosts />
                </OpenDialogListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <OpenDialogListItemButton
                  buttonLabel="Admin mode"
                  icon={<AdminPanelSettingsIcon />}
                >
                  <AdminToggle />
                </OpenDialogListItemButton>
              </ListItem>
            </List>
          ) : (
            <MagicButton
              numberOfTapsToActivate={5}
              contentTitle="Admin mode"
              contentComponent={<AdminToggle />}
              onClose={handleDrawerClose}
            />
          )}
        </Box>
      </MuiDrawer>
    </div>
  );
}
