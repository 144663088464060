import { Button, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

interface IProps {
  fontSize?: string;
  fontWeight?: string;
  onClick: () => void;
}

export function SlimButton(props: PropsWithChildren<IProps>) {
  const { fontSize, fontWeight, onClick, children } = props;

  return (
    <Button
      variant="text"
      size="small"
      sx={{
        minWidth: 0,
        px: 0,
        '&:hover': { backgroundColor: 'transparent' },
      }}
      onClick={onClick}
      disableRipple
    >
      <Typography component="span" fontSize={fontSize} fontWeight={fontWeight}>
        {children}
      </Typography>
    </Button>
  );
}
