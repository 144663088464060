import PublishIcon from '@mui/icons-material/Publish';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { UserState, useUserState } from 'providers/UserStateProvider';
import { ChangeEvent, Fragment, useState } from 'react';

interface IProps {
  onClose: () => void;
}

export function UploadUserStateButton(props: IProps) {
  const { onClose } = props;

  const { updateUserState } = useUserState();

  const [open, setOpen] = useState(false);
  const [userStateError, setUserStateError] = useState<string>();
  const [userState, setUserState] = useState<UserState>();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;

    if (files?.[0]) {
      files[0].text().then((text) => {
        const json = JSON.parse(text) as UserState;
        if (json.puzzles) {
          setUserState(json);
          setUserStateError(undefined);
        } else {
          setUserStateError('No puzzles to restore.');
        }
      });
    }
  };

  const handleCancel = () => {
    setUserState(undefined);
  };

  const handleRestore = () => {
    if (userState) {
      updateUserState(userState);
      handleClose();
    }
  };

  const userStatePuzzlesCount = userState ? Object.keys(userState.puzzles).length : 0;

  return (
    <>
      <ListItemButton onClick={handleOpen}>
        <ListItemIcon>
          <PublishIcon />
        </ListItemIcon>
        <ListItemText primary="Restore puzzle data" />
      </ListItemButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="upload-user-state-dialog-title"
        aria-describedby="upload-user-state-dialog-description"
      >
        <DialogTitle id="upload-user-state-dialog-title">Restore puzzle data</DialogTitle>
        {!userState ? (
          <Fragment key="upload">
            <DialogContent>
              <DialogContentText id="upload-user-state-dialog-description" tabIndex={-1}>
                Select a puzzle file to restore.
              </DialogContentText>
              <Box pt={3}>
                <FormControl fullWidth>
                  <Button variant="contained" component="label">
                    Upload file
                    <input type="file" accept=".json" hidden onChange={handleChange} />
                  </Button>
                </FormControl>
                {userStateError && (
                  <Box pt={2}>
                    <Alert severity="error">{userStateError}</Alert>
                  </Box>
                )}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Fragment>
        ) : (
          <Fragment key="restore">
            <DialogContent>
              <DialogContentText id="upload-user-state-dialog-description" tabIndex={-1}>
                The selected file contains words for{' '}
                <strong>{userStatePuzzlesCount} puzzles</strong>. Restore this data and overwrite
                your current puzzles?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button onClick={handleRestore}>Restore puzzles</Button>
            </DialogActions>
          </Fragment>
        )}
      </Dialog>
    </>
  );
}
