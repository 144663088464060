import { Typography } from '@mui/material';

interface IProps {
  value: number;
  isMax?: boolean;
}

export function Score(props: IProps) {
  const { value, isMax } = props;

  return (
    <Typography component="span" fontWeight={isMax ? 'bold' : 'normal'}>
      {value}
    </Typography>
  );
}
