import DoneIcon from '@mui/icons-material/Done';
import { Button, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';

interface IProps {
  text: string;
}

export function CopyToClipboardButton(props: IProps) {
  const { text } = props;

  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => setIsCopied(false), 1000);
    }
  }, [isCopied]);

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };

  if (isCopied) {
    return (
      <Tooltip open title="Copied!" placement="top" arrow>
        <Button variant="contained" color="success" onClick={handleCopy}>
          <DoneIcon />
        </Button>
      </Tooltip>
    );
  }

  return (
    <Button variant="contained" onClick={handleCopy}>
      Copy
    </Button>
  );
}
