import { Checkbox, FormControlLabel } from '@mui/material';
import { useUserState } from 'providers/UserStateProvider';

export function AdminToggle() {
  const { userState, updateUserState } = useUserState();

  const handleChange = () => {
    updateUserState((prevUserState) => ({ ...prevUserState, isAdmin: !prevUserState.isAdmin }));
  };

  return (
    <FormControlLabel
      control={<Checkbox checked={userState.isAdmin} />}
      label="Enable admin mode"
      onChange={handleChange}
    />
  );
}
