import { Alert, Box, CircularProgress, Typography } from '@mui/material';
import { useBlossomPuzzles } from 'providers/BlossomPuzzlesProvider';

export function LoadingStatusBar() {
  const { isLoading, error } = useBlossomPuzzles();

  if (!isLoading && !error) {
    return null;
  }

  return (
    <Box pt={2}>
      {isLoading && (
        <Typography>
          <CircularProgress size="1rem" /> <i>loading words...</i>
        </Typography>
      )}
      {error && <Alert severity="error">{error}</Alert>}
    </Box>
  );
}
