import { Paper, Stack, Typography } from '@mui/material';
import { addDays, format } from 'date-fns';
import { useBlossomPuzzles } from 'providers/BlossomPuzzlesProvider';
import { useDateKey } from 'providers/DateKeyProvider';
import { interlace } from 'utils/arrayUtils';
import { getDateKey } from 'utils/dateUtils';
import { getBestWordPlacements, getTotalScore } from 'utils/wordsUtils';
import { CopyToClipboardButton } from './CopyToClipboardButton';

function CopyableText({ text }: { text: string }) {
  return (
    <Paper>
      <Stack padding={2} spacing={2}>
        <Typography fontSize="small" whiteSpace="pre-wrap">
          {text}
        </Typography>
        <CopyToClipboardButton text={text} />
      </Stack>
    </Paper>
  );
}

export function SocialMediaPosts() {
  const { dateKey: todaysDateKey } = useDateKey();
  const yesterdaysDate = addDays(new Date(todaysDateKey), -1);
  const yesterdaysDateKey = getDateKey(yesterdaysDate);

  const { getBlossomPuzzleData } = useBlossomPuzzles();

  const todaysPuzzle = getBlossomPuzzleData(todaysDateKey);
  const yesterdaysPuzzle = getBlossomPuzzleData(yesterdaysDateKey);

  if (!todaysPuzzle || !yesterdaysPuzzle) {
    return null;
  }

  const todaysTotal = getTotalScore(
    getBestWordPlacements(todaysPuzzle.validWords, todaysPuzzle.letters),
  );

  // Interlace results into two loops of letters, higher scores in the first loop
  const yesterdaysWordPlacements = interlace(
    getBestWordPlacements(yesterdaysPuzzle.validWords, yesterdaysPuzzle.letters),
  );

  const yesterdaysTotal = getTotalScore(yesterdaysWordPlacements);

  const yesterdaysDateFormat = format(yesterdaysDate, 'do MMM');

  const yesterdaysBestWords = `${yesterdaysDateFormat}'s best words:\n\n${yesterdaysWordPlacements
    .map(({ word, letter, score }) => `${letter}: ${word} (${score})`)
    .join('\n')}\n\nTotal: ${yesterdaysTotal}!`;

  const todaysHighScore = `Today's highest possible Blossom score is ${todaysTotal}. How well did you do??`;

  return (
    <Stack spacing={2}>
      <CopyableText text={yesterdaysBestWords} />
      <CopyableText text={todaysHighScore} />
    </Stack>
  );
}
