import { Clear } from '@mui/icons-material';
import {
  Alert,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
} from '@mui/material';
import { useBlossomPuzzleData } from 'providers/BlossomPuzzleDataProvider';
import { useUserState } from 'providers/UserStateProvider';
import { ChangeEvent, FormEvent, useRef, useState } from 'react';
import { WordValidationResult } from 'types/WordValidationResult';
import { validateWord } from 'utils/wordsUtils';
import { LinkToDefinition } from './LinkToDefinition';

export function WordChecker() {
  const { puzzleId, center, letters, validWords } = useBlossomPuzzleData();
  const { updateUserState } = useUserState();

  const inputRef = useRef<HTMLInputElement>();
  const [testWord, setTestWord] = useState('');
  const [validationResult, setValidationResult] = useState<WordValidationResult | null>(null);

  const handleChangeWord = (e: ChangeEvent<HTMLInputElement>) => {
    setValidationResult(null);
    setTestWord(e.target.value.replace(/[^a-zA-Z]/g, ''));
  };

  const handleClear = () => {
    setValidationResult(null);
    setTestWord('');
    inputRef.current?.focus();
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    if (testWord) {
      const testWordUpperCase = testWord.toUpperCase();
      const result = validateWord(testWordUpperCase, center, letters, validWords);
      setValidationResult(result);

      if (result === true) {
        // Add word to current puzzle's words
        updateUserState((prevUserState) => {
          const puzzle = prevUserState.puzzles[puzzleId];
          if (!puzzle) {
            return {
              ...prevUserState,
              puzzles: {
                [puzzleId]: { words: [testWordUpperCase], placements: [] },
                ...prevUserState.puzzles,
              },
            };
          }
          if (!puzzle.words.includes(testWordUpperCase)) {
            return {
              ...prevUserState,
              puzzles: {
                ...prevUserState.puzzles,
                [puzzleId]: { ...puzzle, words: puzzle.words.concat(testWordUpperCase) },
              },
            };
          }
          return prevUserState;
        });
      }
    }

    inputRef.current?.focus();
    e.preventDefault();
    return false;
  };

  return (
    <Stack direction="column" spacing={2}>
      <form
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
        onSubmit={handleSubmit}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="word-checker-input">Enter a word</InputLabel>
            <OutlinedInput
              id="word-checker-input"
              inputRef={inputRef}
              autoFocus
              label="Enter a word"
              onChange={handleChangeWord}
              value={testWord}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="clear input" onClick={handleClear} edge="end">
                    <Clear />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Button variant="contained" type="submit">
            Check
          </Button>
        </Stack>
      </form>
      {validationResult === true && (
        <Alert severity="success">
          <LinkToDefinition word={testWord} /> is valid!
        </Alert>
      )}
      {typeof validationResult === 'string' && <Alert severity="error">{validationResult}</Alert>}
    </Stack>
  );
}
