import { Slide } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { Drawer } from './Drawer';
import { PuzzlePicker } from './PuzzlePicker';

export function AppBar() {
  const scrollTrigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!scrollTrigger}>
      <MuiAppBar component="nav">
        <Toolbar sx={{ margin: 'auto', maxWidth: 444, width: '100%' }}>
          <Drawer />
          <Typography variant="h6" component="div" flexGrow={1}>
            Blossom Boss
          </Typography>
          <PuzzlePicker />
        </Toolbar>
      </MuiAppBar>
    </Slide>
  );
}
