import { Box, Stack } from '@mui/material';
import { useBlossomPuzzleData } from 'providers/BlossomPuzzleDataProvider';
import { useDateKey } from 'providers/DateKeyProvider';
import { useUserState } from 'providers/UserStateProvider';
import { getDateKey, toFriendlyDate } from 'utils/dateUtils';
import { BestWordPlacementsLoader } from './BestWordPlacementsLoader';
import { OpenDialogButton } from './OpenDialogButton';

export function PastPuzzleActions() {
  const { dateKey } = useDateKey();
  const { puzzleId, validWords, letters } = useBlossomPuzzleData();
  const { userState } = useUserState();

  const todayDateKey = getDateKey(new Date());

  if (dateKey >= todayDateKey) {
    return null;
  }

  const puzzle = userState.puzzles[puzzleId];

  return (
    <Box pt={2}>
      <Stack spacing={2}>
        {puzzle?.words.length >= 12 && (
          <OpenDialogButton buttonLabel="View my best placements" dialogTitle="My best placements">
            <BestWordPlacementsLoader words={puzzle.words} letters={letters} />
          </OpenDialogButton>
        )}
        <OpenDialogButton
          buttonLabel={`View best words for ${toFriendlyDate(dateKey)}`}
          dialogTitle={`Best words for ${toFriendlyDate(dateKey)}`}
        >
          <BestWordPlacementsLoader words={validWords} letters={letters} />
        </OpenDialogButton>
      </Stack>
    </Box>
  );
}
