import { Box } from '@mui/material';
import { useBlossomPuzzleData } from 'providers/BlossomPuzzleDataProvider';
import { useUserState } from 'providers/UserStateProvider';
import { getMaxWordScore, getWordScore } from 'utils/wordsUtils';
import { WordPlacementsDisplay } from './WordPlacementsDisplay';

export function MyPlacements() {
  const { puzzleId, letters } = useBlossomPuzzleData();
  const { userState, updateUserState } = useUserState();

  const puzzle = userState.puzzles[puzzleId];

  if (!puzzle || puzzle.placements.length === 0) {
    return null;
  }

  const wordPlacements = puzzle.placements.map(({ word, letter }) => ({
    word,
    letter,
    score: getWordScore(word, letter),
    maxScore: getMaxWordScore(word, letters),
  }));

  const handleRemove = (word: string, letter: string) => {
    updateUserState((prevUserState) => {
      const prevPuzzle = prevUserState.puzzles[puzzleId];
      return {
        ...prevUserState,
        puzzles: {
          ...prevUserState.puzzles,
          [puzzleId]: {
            ...prevPuzzle,
            placements: prevPuzzle.placements.filter(
              (placement) => !(placement.word === word && placement.letter === letter),
            ),
          },
        },
      };
    });
  };

  return (
    <Box pb={2}>
      <WordPlacementsDisplay wordPlacements={wordPlacements} onRemove={handleRemove} />
    </Box>
  );
}
