import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';

interface IProps {
  numberOfTapsToActivate: number;
  contentTitle: string;
  contentComponent: ReactNode;
  onClose?: () => void;
}

export function MagicButton(props: IProps) {
  const { numberOfTapsToActivate, contentTitle, contentComponent, onClose } = props;

  const [tapCount, setTapCount] = useState(0);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (tapCount >= numberOfTapsToActivate) {
      setShowContent(true);
      setTapCount(0);
    }
  }, [tapCount]);

  const handleClick = () => setTapCount((prevTapCount) => prevTapCount + 1);

  const handleClose = () => {
    setShowContent(false);

    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      <Button fullWidth size="large" disableRipple onClick={handleClick} />
      <Dialog open={showContent} onClose={handleClose} aria-labelledby="magic-button-dialog-title">
        <DialogTitle id="magic-button-dialog-title">{contentTitle}</DialogTitle>
        <DialogContent>{contentComponent}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
