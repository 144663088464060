import { PaletteMode, ThemeProvider, createTheme } from '@mui/material';
import { BlossomPuzzlesProvider } from 'providers/BlossomPuzzlesProvider';
import { DateKeyProvider } from 'providers/DateKeyProvider';
import { UserStateProvider } from 'providers/UserStateProvider';
import { PropsWithChildren, useState } from 'react';

function matchPrefersColorSchemeDark(): MediaQueryList {
  return window.matchMedia?.('(prefers-color-scheme: dark)');
}

export function Providers({ children }: PropsWithChildren) {
  const [paletteMode, setPaletteMode] = useState<PaletteMode>(
    matchPrefersColorSchemeDark()?.matches ? 'dark' : 'light',
  );

  // Watch for changes
  matchPrefersColorSchemeDark()?.addEventListener('change', (e) => {
    setPaletteMode(e.matches ? 'dark' : 'light');
  });

  const theme = createTheme({ palette: { mode: paletteMode } });

  return (
    <ThemeProvider theme={theme}>
      <DateKeyProvider>
        <UserStateProvider>
          <BlossomPuzzlesProvider>{children}</BlossomPuzzlesProvider>
        </UserStateProvider>
      </DateKeyProvider>
    </ThemeProvider>
  );
}
