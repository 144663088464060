import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { add } from 'date-fns';
import { useBlossomPuzzles } from 'providers/BlossomPuzzlesProvider';
import { useDateKey } from 'providers/DateKeyProvider';
import { useState } from 'react';
import { getDateKey, toFriendlyDate } from 'utils/dateUtils';

export function PuzzlePicker() {
  const { dateKey: initialDateKey, updateDateKey } = useDateKey();
  const { dateKeys } = useBlossomPuzzles();

  const [open, setOpen] = useState(false);
  const [dateKey, setDateKey] = useState(initialDateKey);

  if (!dateKeys) {
    return null;
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setDateKey(event.target.value);
  };

  const handleUpdate = () => {
    updateDateKey(dateKey);
    setOpen(false);
  };

  const tomorrowDateKey = getDateKey(add(new Date(), { days: 1 }));
  const dates = Array.from(dateKeys.keys())
    .reverse()
    .filter((d) => d <= tomorrowDateKey)
    .map((d) => [d, dateKeys.get(d)]);

  return (
    <>
      <Button color="inherit" onClick={handleOpen} sx={{ textTransform: 'none' }}>
        {toFriendlyDate(initialDateKey)}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="puzzle-picker-dialog-title"
        aria-describedby="puzzle-picker-dialog-description"
      >
        <DialogTitle id="puzzle-picker-dialog-title">Puzzle picker</DialogTitle>
        <DialogContent>
          <DialogContentText id="puzzle-picker-dialog-description" tabIndex={-1}>
            Choose which puzzle you want to play.
          </DialogContentText>

          <Box pt={3}>
            <FormControl fullWidth>
              <InputLabel id="puzzle-picker-select-label">Puzzle</InputLabel>
              <Select
                labelId="puzzle-picker-select-label"
                id="puzzle-picker-select"
                value={dateKey}
                label="Puzzle"
                onChange={handleChange}
              >
                {dates.map(([key, label]) => (
                  <MenuItem key={key} value={key}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdate}>Go!</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
