import { Box } from '@mui/material';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import { AdminStatusBar } from 'components/AdminStatusBar';
import { AppBar } from 'components/AppBar';
import { LettersDisplay } from 'components/LettersDisplay';
import { LoadingStatusBar } from 'components/LoadingStatusBar';
import { MyPlacements } from 'components/MyPlacements';
import { MyWordScores } from 'components/MyWordScores';
import { PastPuzzleActions } from 'components/PastPuzzleActions';
import { WordChecker } from 'components/WordChecker';
import { BlossomPuzzleDataProvider } from 'providers/BlossomPuzzleDataProvider';
import { useBlossomPuzzles } from 'providers/BlossomPuzzlesProvider';
import { useDateKey } from 'providers/DateKeyProvider';

export function App() {
  const { dateKey } = useDateKey();
  const { getBlossomPuzzleData } = useBlossomPuzzles();
  const blossomPuzzleData = getBlossomPuzzleData(dateKey);

  return (
    <Container maxWidth="xs">
      <AppBar />
      <Box component="main" pb={2}>
        <Toolbar />
        <LoadingStatusBar />
        <AdminStatusBar />
        {blossomPuzzleData && (
          <BlossomPuzzleDataProvider key={dateKey} value={blossomPuzzleData}>
            <Box pt={2}>
              <LettersDisplay />
            </Box>
            <Box pt={2}>
              <WordChecker />
            </Box>
            <Box pt={2}>
              <MyPlacements />
              <MyWordScores />
            </Box>
            <PastPuzzleActions />
          </BlossomPuzzleDataProvider>
        )}
      </Box>
    </Container>
  );
}
