import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useUserState } from 'providers/UserStateProvider';
import { getLocalTimezoneDate } from 'utils/dateUtils';

export function DownloadUserStateButton() {
  const { userState } = useUserState();

  const handleClick = () => {
    const now = getLocalTimezoneDate(new Date());
    const filenameDatePart = now.toISOString().split('.')[0].replaceAll(/[:T]/g, '-');
    const filename = `blossom-boss-puzzles-${filenameDatePart}.json`;
    const a = document.createElement('a');
    a.download = filename;
    a.href = window.URL.createObjectURL(new Blob([JSON.stringify(userState)]));
    a.click();
  };

  return (
    <ListItemButton onClick={handleClick}>
      <ListItemIcon>
        <FileDownloadIcon />
      </ListItemIcon>
      <ListItemText primary="Save puzzle data" />
    </ListItemButton>
  );
}
